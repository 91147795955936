.container {
  margin-inline: auto;
  max-width: 1200px;
  padding-inline: 1rem;
}
.container.small {
  max-width: 496px;
}
.container.medium {
  max-width: 768px;
}
.container.large {
  max-width: 1200px;
}