.container {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}
.head .row {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
}
.body .row:nth-child(odd) {
  background-color: var(--color-surface);
}
.cell {
  padding: 1rem;
  white-space: nowrap;
}
.cell.no-data {
  text-align: center;
}
.sort {
  margin-left: 0.4rem;
}
.sort.hidden {
  opacity: 0;
}