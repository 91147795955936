.allocating {
  background-color: var(--color-accent);
  color: var(--color-on-accent);
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  padding: 1rem;
}
.actions {
  display: flex;
  gap: 1rem;
}
.button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.button[disabled] {
  cursor: default;
  opacity: 0.5;
}