.blocks {
  color: white;
  display: grid;
  margin-inline: auto;
  max-width: 48rem;
  position: relative;
  z-index: 10;
}
.blocks .block {
  align-items: center;
  background-color: var(--color-accent);
  display: flex;
  height: 150px;
  gap: 1rem;
  justify-content: center;
  position: relative;
  padding-block: 1rem;
  background-image: linear-gradient(
    160deg,
    transparent 0%,
    transparent 50%,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0.05) 100%
  );
  border: 2px solid rgba(0, 0, 0, 0.02);
  box-shadow: inset 0 0 30px rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
.blocks .block:nth-child(1) {
  background-color: var(--color-accent-2);
}
.blocks .block:nth-child(2) {
  background-color: var(--color-accent-3);
}
.blocks .block .icon {
  font-size: 2rem;
}
@media screen and (min-width: 576px) {
  .blocks {
    grid-template-columns: repeat(2, 1fr);
  }
  .blocks .block {
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .blocks {
    margin-top: -75px;
  }
  .blocks .block {
    border-radius: 0.5rem;
    transition: transform var(--transition-duration);
  }
}