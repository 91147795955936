:root {
  /* Breakpoints. */
  --breakpoint-tablet: 768px;

  /* Buttons. */
  --button-color-accent: #63cae0;
  --button-color-on-accent: #fff;
  --button-color-primary: #222;
  --button-color-on-primary: #fff;
  --button-color-secondary: #fff;
  --button-color-on-secondary: #222;
  
  /* Colors. */
  --color-accent: #63cae0;
  --color-accent-2: #cccd33;
  --color-accent-3: #ff9933;
  --color-border: #ccc;
  --color-primary: #222;
  --color-secondary: #fff;
  --color-background: #fff;
  --color-surface: #f0f0f0;
  --color-error: #c90000;
  --color-disable: #aaa;
  --color-on-accent: #fff;
  --color-on-primary: #fff;
  --color-on-background: #222;
  --color-on-secondary: #fff;
  --color-on-surface: #222;
  --color-on-error: #fff;
  --color-on-disable: #666;

  /* Fonts. */
  --font-family-primary: 'Lato', sans-serif;

  /* Header. */
  --header-height: 8rem;

  /* Transitions. */
  --transition-duration: 300ms;
}

.dark {
  /* Buttons. */
  --button-color-primary: #63cae0;
  --button-color-on-primary: #fff;

  /* Colors. */
  --color-background: #222;
  --color-primary: #333;
  --color-secondary: #222;
  --color-surface: #333;
  --color-on-background: #FFF;
  --color-on-primary: #ccc;
  --color-on-surface: #fff;
}