.nav {
  display: flex;
  justify-content: center;
  padding-block: 1rem;
}

.nav a {
  text-decoration: none;
}

.nav ul {
  display: flex;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}