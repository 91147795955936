.details dt {
  font-size: 0.8rem;
  font-weight: bold;
}

.details dd {
  margin-bottom: 1rem;
}

.description {
  white-space: pre-wrap;
}