.mission {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.mission h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
.mission p + p {
  margin-top: 1rem;
}