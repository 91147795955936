.error {
  align-items: center;
  background-color: white;
  bottom: 0;
  color: #222;
  display: flex;
  left: 0;
  justify-content: center;
  padding: 2rem;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.image {
  margin-block: 2rem;
  width: 300px;
}