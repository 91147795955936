.footer {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  font-size: 0.8rem;
  padding-block: 2rem;
  text-align: center;
  transition: background-color var(--transition-duration), color var(--transition-duration);
}

.footer a {
  text-decoration: none;
}

.container {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.links li {
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .links {
    flex-direction: row;
  }
}

.container {
  height: 100%;
}