.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.modal {
  background-color: var(--color-background);
  border-radius: 0.5rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
  color: var(--color-on-background);
  display: grid;
  grid-template-rows: auto 1fr auto;
  left: 50%;
  max-height: calc(100vh - 2rem);
  max-width: calc(100vw - 2rem);
  overflow: hidden;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 768px;
  z-index: 1000;
}

.header {
  align-items: center;
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
}

.close {
  background: none;
  border: none;
  cursor: pointer;
  padding: 1rem;
}

.content {
  padding: 2rem;
  overflow-y: auto;
}

.footer {
  border-top: 1px solid var(--color-border);
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
}