.container {
  display: flex;
  margin-bottom: 2rem;
}
.button {
  width: 100%;
}
.form {
  display: grid;
  gap: 1rem;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .container {
    margin-bottom: 1rem;
  }
  .button {
    width: unset;
  }
  .form {
    grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
    margin-bottom: 0;
  }
}