.header {
  background-color: var(--color-background);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  color: var(--color-on-background);
  font-size: 0.8rem;
  height: calc(var(--header-height) - 25px);
  position: sticky;
  top: 0;
  transition: background-color var(--transition-duration), color var(--transition-duration);
  z-index: 999;
}

.header .container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .header .container {
    justify-content: space-between;
  }
}

.header a {
  text-decoration: none;
}

.header .mobile-nav-trigger {
  background: none;
  border: none;
  cursor: pointer;
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.header .nav ul {
  align-items: center;
  display: flex;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header .logout {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .header {
    height: var(--header-height);
    padding-bottom: 25px;
  }
}