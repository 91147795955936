.root {
  --toggle-height: 32px;
  --toggle-width: 56px;

  all: unset;
  background-color: #87ceeb;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 9999px;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: var(--toggle-height);
  position: relative;
  transition:
    background-color var(--transition-duration) ease,
    border-color var(--transition-duration) ease;
  width: var(--toggle-width);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.root:focus {
  boxShadow: 0 0 0 2px black;
}
.root[data-state="checked"] {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
}

.thumb {
  --color-moon-1: #EDDDD4;
  --color-moon-2: #FFFFFF;  
  --color-sun-1: #FFFFFF;
  --color-sun-2: #FFBE0B;
  --color-sun-3: #FB5607;
  --thumb-dimension: calc(var(--toggle-height) / 1.75);
  --thumb-offset: calc((var(--toggle-height) - var(--thumb-dimension)) / 2);
  
  background-image: radial-gradient(
    ellipse at center,
    var(--color-sun-1) 0%,
    var(--color-sun-2) 50%,
    var(--color-sun-3) 100%
  );
  border-radius: 9999px;
  box-shadow: 0 0 5px 1px var(--color-sun-3);
  display: block;
  height: var(--thumb-dimension);
  overflow: hidden;
  position: relative;
  transform: translateX(var(--thumb-offset));
  transition: background-image var(--transition-duration), box-shadow var(--transition-duration), transform var(--transition-duration);
  width: var(--thumb-dimension);
  willChange: transform;
}
.thumb:before {
  background-image: linear-gradient(135deg, var(--color-moon-1) 0%, var(--color-moon-1) 50%, var(--color-moon-2) 50%, var(--color-moon-2) 100%);
  content: '';
  display: block;
  border-radius: 9999px;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  transform-origin: center;
  transition: opacity var(--transition-duration);
  top: 0;
  width: 100%;
}
.thumb[data-state="checked"] {
  transform:
    translateX(calc(var(--toggle-width) - var(--thumb-offset) - var(--thumb-dimension)))
    rotate(180deg);
}
:global(.dark) .thumb {
  box-shadow: 0 0 5px 1px var(--color-moon-2);
}
:global(.dark) .thumb:before {
  opacity: 1;
}