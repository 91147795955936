.onboarding {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  max-width: 1200px;
  padding-inline: 1rem;
  text-align: center;
}
.onboarding h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
.onboarding h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
.onboarding h4 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.onboarding p {
  margin-bottom: 3rem;
}
.onboarding svg {
  height: 10rem;
  width: auto;
  margin-bottom: 1rem;
}

.step {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  max-width: 24rem;
}

.step-1 {
  align-items: flex-end;
  display: grid;
  gap: 1rem;
  margin-bottom: 4rem;
}
@media screen and (min-width: 768px) {
  .step-1 {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    max-width: unset;
  }
}

.step-2 {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .buttons {
    flex-direction: row;
  }
}