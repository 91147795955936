.header {
  margin-bottom: 2rem;
}
.header h1 {
  margin-bottom: 1rem;
}

.form {
  display: grid;
  gap: 1rem;
}