.allocating {
  background-color: var(--color-accent);
  border-radius: 0.5rem;
  color: var(--color-on-accent);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
}

.header {
  margin-bottom: 1rem;
}
.header h1 {
  margin-bottom: 1rem;
}

.filters, .filter-row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.filters {
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .filter-row {
    flex-direction: row;
  }
}