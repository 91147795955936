.container {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}
.container.disabled {
  opacity: 0.5;
}

.root {
  all: unset;
  align-items: center;
  background-color: white;
  border: 1px solid var(--color-primary);
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;
}
.root:focus {
  boxShadow: 0 0 0 2px black;
}

.indicator {
  color: var(--color-primary);
  font-size: 0.65rem;
  line-height: 1em;
}