.container {
  --input-height: 3.5rem;
  --input-font-size: 1rem;
  --input-padding: 0.5rem;
  --input-padding-top: 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  width: 100%;
}

.label {
  font-size: calc(var(--input-font-size) * 0.8);
  font-weight: bold;
  left: var(--input-padding);
  line-height: calc(var(--input-font-size) * 0.8);
  pointer-events: none;
  position: absolute;
  top: var(--input-padding);
  transition: color var(--transition-duration);
  white-space: nowrap;
}
.errored .label {
  color: var(--color-error);
}

.input {
  background-color: var(--color-surface);
  border: 1px solid var(--color-on-surface);
  border-radius: 0.5rem;
  color: var(--color-on-surface);
  font-size: var(--input-font-size);
  height: var(--input-height);
  line-height: var(--input-font-size);
  outline: none;
  padding: var(--input-padding-top) var(--input-padding) var(--input-padding);
  transition: background-color var(--transition-duration), border-color var(--transition-duration), color var(--transition-duration);
  width: 100%;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input[type=number] {
  -moz-appearance: textfield;
}
.errored .input {
  border-color: var(--color-error);
  color: var(--color-error);
}

.input[disabled] {
  opacity: 0.5;
}

.error {
  color: var(--color-error);
  display: inline-block;
  font-size: calc(var(--input-font-size) * 0.8);
  font-weight: bold;
  margin-left: var(--input-padding);
}