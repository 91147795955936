.heading {
  margin-bottom: 2rem;
  text-align: center;
}
.heading strong {
  font-weight: 800;
}

.form {
  display: grid;
  gap: 1rem;
  text-align: center;
}