.disabled {
  color: var(--color-error);
  font-weight: bold;
  margin-bottom: 1rem;
}
.form {
  display: grid;
  gap: 1rem;
}
.form fieldset {
  border: none;
  display: grid;
  gap: 1rem;
  margin: 0;
  padding: 0;
}
.form legend {
  font-weight: bold;
  margin-bottom: 1rem;
}