@media screen and (min-width: 768px) {
  .hero {
    margin-top: -25px;
    padding-bottom: 150px;
    padding-top: 75px;
  }
}

.buttons {
  display: flex;
  gap: 1rem;
}
