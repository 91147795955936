.actions {
  display: flex;
  gap: 1rem;
}
.button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.button[disabled] {
  cursor: default;
  opacity: 0.5;
}