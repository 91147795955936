.button {
  --button-height: 3.5rem;

  align-items: center;
  background: none;
  border: 2px solid transparent;
  border-radius: 9999rem;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  font-weight: bold;
  height: var(--button-height);
  padding-inline: 2rem;
  text-decoration: none;
  transition: background-color var(--transition-duration), border-color var(--transition-duration), color var(--transition-duration);
  white-space: nowrap;
}
.button.accent {
  background-color: var(--button-color-accent);
  color: var(--button-color-on-accent);
}
.button.accent[disabled] {
  background-color: var(--color-disable);
  color: var(--color-on-disable);
  cursor: default;
}
.button.primary {
  background-color: var(--button-color-primary);
  color: var(--button-color-on-primary);
}
.button.primary[disabled] {
  background-color: var(--color-disable);
  color: var(--color-on-disable);
  cursor: default;
}
.button.secondary {
  background-color: transparent;
  border-color: var(--button-color-primary);
  color: var(--button-color-primary);
}
.button.secondary[disabled] {
  border-color: var(--color-disable);
  color: var(--color-disable);
  cursor: default;
}
.button.secondary-inverse {
  background-color: transparent;
  border-color: var(--button-color-secondary);
  color: var(--button-color-secondary);
}
.button.secondary-inverse[disabled] {
  border-color: var(--color-disable);
  color: var(--color-disable);
  cursor: default;
}

.button.small {
  height: calc(var(--button-height) * 0.75);
}
.button.medium {
  height: var(--button-height);
}
