.logo {
  --logo-height: 5rem;

  align-items: center;
  color: #009900;
  display: flex;
  flex-direction: column;
  font-family: 'TypoGraphica', sans-serif;
  font-size: calc(var(--logo-height) / 3);
  height: var(--logo-height);
  position: relative;;
}

.logo img {
  height: 75%;
}
.logo span {
  margin-top: -1em;
}