.overlay {
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: background-color 300ms;
  z-index: 999;
}
:global(.dark) .overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.drawer {
  background-color: #222;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
  color: #fff;
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  z-index: 999;
  overflow-y: auto;
}
.header {
  background-color: var(--color-accent);
  display: flex;
  height: 50px;
  position: sticky;
  top: 0;
  justify-content: flex-start;
}
.close {
  align-items: center;
  background-color: var(--color-accent);
  border: none;
  color: var(--color-on-accent);
  cursor: pointer;
  justify-content: flex;
  display: flex;
  padding: 1rem;
}
.nav a {
  align-items: center;
  background-color: #252525;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  justify-content: space-between;
  padding: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 300ms;
}
.nav a:hover {
  background-color: #282828;
}
.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav li {
  border-bottom: 1px solid #333;
}
.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
}
.footer a {
  text-decoration: none;
}

.admin {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.admin .details {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.admin .link {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}
.admin .button {
  width: 100%;
}

.socials {
  align-items: center;
  flex-direction: column;
  display: flex;
  gap: 1rem;
}