.app {
  background-color: var(--color-background);
  color: var(--color-on-background);
  transition: background-color var(--transition-duration), color var(--transition-duration);
}

.spinner {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}