.disabled {
  color: var(--color-error);
  font-weight: bold;
  margin-bottom: 1rem;
}

.form {
  display: grid;
  gap: 1rem;
}
.form fieldset {
  border: none;
  display: grid;
  gap: 1rem;
  margin: 0;
  padding: 0;
}
.form legend {
  font-weight: bold;
  margin-bottom: 1rem;
}
.amount {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
@media screen and (min-width: 768px) {
  .amount {
    grid-template-columns: 1fr 3fr;
  }
}

.stripe {
  justify-self: center;
  width: 150px;
}
