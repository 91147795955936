body {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  font-family: var(--font-family-primary);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1em;
}

a {
  color: inherit;
}

ul {
  margin: 0;
}

button {
  color: inherit;
}