.id {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.section h2 {
  margin-bottom: 1rem;
}
.section + .section {
  margin-top: 3rem;
}

.details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.details dt {
  font-size: 0.8rem;
  font-weight: bold;
}
.details dd {
  margin-bottom: 1rem;
}

.description {
  white-space: pre-wrap;
}