.hero {
  align-items: center;
  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  margin-top: 0;
  padding-inline: 1rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: relative;
}
.hero:before {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.hero .content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-inline: auto;
  max-width: 48rem;
  position: relative;
  text-align: center;
}
.hero .content h1 {
  font-size: 1.5rem;
  font-weight: 800;
}
.hero .content p {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .hero {
    margin-top: -25px;
    padding-bottom: 3rem;
    padding-top: 75px;
  }
  .hero .content {
    gap: 2rem;
  }
  .hero .content h1 {
    font-size: 2.5rem;
  }
  .hero .content p {
    font-size: 1.25rem;
  }
}