.nav {
  height: 50px;
  font-size: 1rem;
  margin-top: -25px;
  margin-inline: auto;
  max-width: 1200px;
  padding-inline: 1rem;
  position: sticky;
  top: calc(var(--header-height) - 25px);
  width: 100%;
  z-index: 999;
}

.nav .container {
  align-items: center;
  background-color: var(--color-accent);
  color: var(--color-on-accent);
  border-radius: 0.25rem;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
}

.nav a {
  font-weight: bold;
  text-decoration: none;
}

.nav ul {
  display: flex;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}