.container {
  --input-height: 3.5rem;
  --input-font-size: 1rem;
  --input-padding: 0.5rem;
  --input-padding-top: 1.5rem;

  width: 100%;
  position: relative;
}
.container.disabled {
  opacity: 0.5;
}

.label {
  font-size: calc(var(--input-font-size) * 0.8);
  font-weight: bold;
  left: var(--input-padding);
  line-height: calc(var(--input-font-size) * 0.8);
  position: absolute;
  top: var(--input-padding);
  transition: color var(--transition-duration);
  z-index: 1;
}
.errored .label {
  color: var(--color-error);
}

.container :global(.select__control) {
  background-color: var(--color-surface);
  border-color: var(--color-on-surface);
  box-shadow: none;
  color: var(--color-on-surface);
  border-radius: 0.5rem;
}
.container :global(.select__control):hover {
  border-color: var(--color-on-surface);
}
.errored :global(.select__control) {
  border-color: var(--color-error);
  color: var(--color-error);
}

.container :global(.select__value-container) {
  height: calc(var(--input-height) - 2px);
  padding: var(--input-padding-top) var(--input-padding) var(--input-padding);
}

.container :global(.select__single-value) {
  color: var(--color-on-surface);
  margin: 0;
  padding: 0;
}

.container :global(.select__input-container) {
  margin: 0;
  padding: 0;
}

.container :global(.select__indicator-separator) {
  display: none;
}
.container :global(.select__dropdown-indicator) {
  color: var(--color-on-surface);
}

.container :global(.select__menu) {
  background-color: var(--color-surface);
  color: var(--color-on-surface);
  overflow: hidden;
}
.container :global(.select__menu-list) {
  padding: 0;
}

.container :global(.select__option) {
  min-height: 40px;
}
.container :global(.select__option):active {
  background-color: var(--color-accent);
  color: var(--color-on-accent);
}
.container :global(.select__option--is-selected) {
  background-color: var(--color-accent);
  color: var(--color-on-accent);
}
.container :global(.select__option--is-focused) {
  background-color: var(--color-accent);
  color: var(--color-on-accent);
}

.error {
  color: var(--color-error);
  display: inline-block;
  font-size: calc(var(--input-font-size) * 0.8);
  font-weight: bold;
  margin-left: var(--input-padding);
}